<template>
   <nav class="main-nav transparent stick-fixed wow-menubar">
      <div class="full-wrapper relative clearfix">
         <div class="nav-logo-wrap local-scroll">
            <router-link :to="{ name: 'home' }" class="logo">
                <img src="/images/gpro_logo_b.png" style="width: 70%;" />
            </router-link>
         </div>
         <div class="inner-nav">
            <ul class="clearlist">
               <li>
                 <router-link v-if="sherbimet" :to="{ name: 'home' }" class="mn-has-sub gbtn btnmenu sherbimet">Shërbimet</router-link>
               </li>
            </ul>
         </div>
      </div>
   </nav> 
</template>



<script>
    import hmenu from './header.vue'

    export default {

        data () {
            return {
               sherbimet:true
            }
        },


        mounted:function(){

            if(this.$route.name == 'home'){
               this.sherbimet = false;
            }else{
               this.sherbimet = true
            }

        },


        components: {
            hmenu,
        }


    }

</script>



<style>


.desktop-nav{
    display: inline-block!important;
}

.btnmenu{
    margin-left: 15px;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}


.btn-mod.btn-medium {
    height: auto;
    padding: 11px 15px 12px;
    font-size: 14px;
    width: 100%;
}




    @media
    only screen 
    and (max-width: 988px), (min-device-width: 988px) 
    and (max-device-width: 1024px)  {

      .btn-medium {
         margin-bottom: 10px!important;
      }

   }



    @media only screen and (max-width: 480px){
        .btn-medium {
            margin-bottom: 10px!important;
        }
    }



    .full-wrapper{
   
        max-width: 1318px;
        position: relative;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        padding-right: 30px;
        padding-left: 30px;


    }




    .gbtn{
        height: 42px!important;
        line-height: 40px!important;
        width: 139px;
        background-color: #252526;
        border-radius: 10px!important;
        color: #fff!important;
        font-size: 24px!important;
    }


    .inner-nav {
        padding-top: 15px;
    }

    .camsh ul li{
        text-align: left;
    }

    .body-scrolled{
          box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05)!important;
          background: #fff!important;
    }




</style>