<template>
    <hmenu></hmenu>

   <div>



       <section class="page-section pt-100 mob" id="home">
           <div class="container relative text-center">
               <div class="row">
                   <div class="col-lg-10 offset-lg-1">
                        <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s">Shërbime të IT's</h2>
                        <br>
                        <p class="section-title-descr" style="font-size: 22px!important; text-align: left;">
                           Me përvojë 15 vjecare në IT, Gpro ofron shërbime për të përmirësuar krijimin e strategjisë e IT-së, krijimin e sigurisë së informacionit dhe integrimin e sistemit, sigurimin e nje transformi dixhital të qetë dhe efektiv dhe për të përmirësuar përvojën dixhitale të konsumatorëve. Qasja jonë është që të përqendrohemi në strategjinë e klientëve dhe në qëllimet afatgjata, qëllimi ynë nuk është që të krijojmë zgjidhje afatshkurta por është pajisja e bizneseve me teknologji të besueshme dhe të kualitative në mënyrë që të arrijmë rezultate të qëndrueshme dha afatgjate. 
                        </p>
                   </div>
               </div>
           </div>
       </section>



       <section class="page-section pt-0">
           <div class="container relative">
               
               <div class="alt-service-grid">
                   <div class="row">
                       
                       <div class="col-sm-6 col-md-3 col-lg-3">
                           <div class="alt-service-item">
                               <div class="alt-service-icon">
                                   <i class="fa fa-paper-plane" aria-hidden="true"></i>
                               </div>
                               <h3 class="alt-services-title">Google Workspace</h3>
                           </div>
                       </div>
                       
                       <div class="col-sm-6 col-md-3 col-lg-3">
                           <div class="alt-service-item">
                               <div class="alt-service-icon">
                                   <i class="fa fa-cogs" aria-hidden="true"></i>
                               </div>
                               <h3 class="alt-services-title">Microsoft 365</h3>
                           </div>
                       </div>
                       
                       <div class="col-sm-6 col-md-3 col-lg-3">
                           <div class="alt-service-item">
                               <div class="alt-service-icon">
                                   <i class="fa fa-feather" aria-hidden="true"></i>
                               </div>
                               <h3 class="alt-services-title">Endpoint Antivirus</h3>
                           </div>
                       </div>
                       
                       <div class="col-sm-6 col-md-3 col-lg-3">
                           <div class="alt-service-item">
                               <div class="alt-service-icon">
                                   <i class="fa fa-life-ring" aria-hidden="true"></i>
                               </div>
                               <h3 class="alt-services-title">Cloud Backup</h3>
                           </div>
                       </div>
                       
                   </div>
               </div>
               
           </div>
       </section>



       <section class="page-section pt-0">

           <div class="container relative">
               <div class="row services-grid">
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M13.5 20c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm-10.551 4c-.598 0-2.429-1.754-2.747-4.304-.424-3.414 2.124-5.593 4.413-5.87.587-1.895 2.475-4.684 6.434-4.77.758-1.982 3.409-4.507 6.84-3.186 1.647.634 3.101 2.101 3.705 3.737.231.624-.71.965-.937.347-.51-1.378-1.737-2.615-3.127-3.151-2.577-.99-4.695.731-5.422 2.298 1.107.12 2.092.455 2.755.889.909.594 1.473 1.558 1.508 2.577.031.889-.33 1.687-.991 2.187-.654.496-1.492.643-2.298.404-.966-.286-1.748-1.076-2.143-2.169-.287-.793-.384-1.847-.178-2.921-3.064.185-4.537 2.306-5.075 3.742 1.18.102 2.211.574 2.831 1.012.959.676 1.497 1.6 1.513 2.599.015.859-.363 1.664-1.011 2.155-.608.46-1.535.599-2.363.348-.961-.289-1.7-1.041-2.079-2.118-.255-.723-.375-1.776-.204-2.919-1.631.361-3.512 1.995-3.178 4.685.18 1.448 1.008 2.888 2.015 3.502.43.261.242.926-.261.926zm10.551-1c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm8.011-6.801l2.489.459-1.744 1.833.333 2.509-2.283-1.092-2.283 1.092.333-2.509-1.744-1.833 2.489-.459 1.205-2.225 1.205 2.225zm-1.759.897l-1.143.21.801.843-.153 1.152 1.049-.501 1.049.501-.153-1.152.801-.843-1.143-.21-.554-1.022-.554 1.022zm-14.345-2.3c-.202 1.024-.128 1.993.113 2.678.347.984.966 1.355 1.424 1.492.604.183 1.175.036 1.472-.187.388-.294.624-.808.614-1.34-.011-.673-.398-1.313-1.09-1.801-.545-.385-1.479-.803-2.533-.842zm6.373-4.716c-.226 1.018-.11 1.99.099 2.569.287.79.828 1.356 1.486 1.55.501.148 1.014.06 1.411-.242.398-.301.615-.795.596-1.355-.025-.705-.409-1.353-1.056-1.775-.511-.334-1.448-.657-2.536-.747zm-5.812-7.369l3.032.558-2.124 2.234.405 3.057-2.781-1.331-2.781 1.331.405-3.057-2.124-2.234 3.032-.558 1.468-2.711 1.468 2.711zm-2.285.897l-1.686.31 1.182 1.243-.226 1.7 1.547-.74 1.547.74-.226-1.7 1.182-1.243-1.686-.31-.817-1.508-.817 1.508zm17.817-3.608c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5zm0 4c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Shërbime të administrimit të sistemeve</h3>
                           <div class="services-descr">
                               Nëse kompjuteri juaj Linux/Windows apo ndonjë sistem tjetër kompjuterik ka probleme ose dëshrioni të shtoni veçori të reja në këtë sistem, përvoja jonë 15 vjecare na bën ekipin e përkryer për të ju ndihmuar në çështjet e administrimit të sistemeve.
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M5 22h4v-3h-9v-18h24v18h-10v3h4v1h-13v-1zm5-3v3h3v-3h-3zm13-17h-22v16h22v-16z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Siguria</h3>
                           <div class="services-descr">
                               Shërbimet tona të sigurisë përfshijnë: Mbrojtjen nga Malware, Mbrojtjen nga lëshimet e sigurisë, Ndalimi i sulmeve DDoS, Menaxhimin e Firewall, Mbrojtjen e të Dhënave, Mbrojtjen e DNS, Intrusion Detection Systems (IDS), etj. 
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-1-17h-17v13s-.665-1-2-1c-1.104 0-2 .896-2 2s.896 2 2 2h19v-16zm-18-2.68c-.427-.971-1.327-1.325-2-1.32-.661.005-1.568.3-2 1.32v16.178c.394-1.993 2.245-2.881 4-2.401v-13.777zm15 15.68h-12v-10h12v10zm-8-9h-3v8h10v-4h-2v3h-1v-3h-3v3h-1v-7zm7 0h-6v3h6v-3z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Migrimet dhe Integrimet </h3>
                           <div class="services-descr">
                               Pavarësisht nëse jeni duke u zhvendosur nga një ofrues të shërbimeve tek një tjetër ose keni nevojë të integroni të dhënat e vjetra në sistemin tuaj të ri, ekspertët e Gpro’s mund të ju ndihmojnë me një gamë të plotë shërbimesh dhe të adresojnë çështjet që kanë të bëjnë me migrimin dhe integrimin e të dhënave. 
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M9.484 15.696l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm0-5l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm0-5l-.711-.696-2.552 2.607-1.539-1.452-.698.709 2.25 2.136 3.25-3.304zm10.516 11.304h-8v1h8v-1zm0-5h-8v1h8v-1zm0-5h-8v1h8v-1zm4-5h-24v20h24v-20zm-1 19h-22v-18h22v18z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Virtualizime & Cloud</h3>
                           <div class="services-descr">
                               Për të shpenzuar në mënyrë efikase resurset e serverit, virtualizimi është më se i nevojshëm. Ky është procesi i përdorimit të softuerit në një server fizik për të krijuar ndarje të shumëfishta ose "instanca virtuale", secila nga këto instanca do të jetë e aftë që të funksionojë në mënyrë të pavarur. Lejoni specialistët tanë të ju ndihmojnë në çështjet e virtualizimit.
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M15 7h4v5h5v6h-5v5h-19v-21h15v5zm-5 11h-9v4h9v-4zm8 0h-7v4h7v-4zm-11-5h-6v4h6v-4zm9 0h-8v4h8v-4zm7 0h-6v4h6v-4zm-12-5v4h7v-4h-7zm-1 0h-9v4h9v-4zm-3-5h-6v4h6v-4zm7 0h-6v4h6v-4z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Ruajta dhe rikthimi i të dhënave</h3>
                           <div class="services-descr">
                               Shërbimet e ruajtjes të dhënave jo vetëm që ruajnë të dhënat tuaja të rëndësishme por ato mbrojnë investimet tuaja në të dhëna që mund të jenë të kushtueshme për bizneset tuaja. Por edhe nëse të dhënat ju humbasin, lejoni ekspertët tanë që ti rikthejnë ato nëpërmjet metodave profesionale..
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M14.889 23.652c-.923.227-1.888.348-2.881.348-6.627 0-12.008-5.377-12.008-12s5.381-12 12.008-12c6.628 0 12.009 5.377 12.009 12 0 1.027-.13 2.023-.373 2.975l-.965-.34c.21-.847.321-1.732.321-2.643 0-6.066-4.929-10.992-11-10.992s-11 4.926-11 10.992c0 6.067 4.929 10.993 11 10.993.887 0 1.751-.106 2.577-.304l.312.971zm-2.889-13.652c-1.104 0-2 .896-2 2s.896 2 2 2c.28 0 .546-.057.788-.162l3.06 9.525 2.174-3.622 4.116 4.259 1.879-1.828-4.087-4.216 3.671-1.926-9.694-3.426c.06-.19.093-.393.093-.604 0-1.104-.896-2-2-2zm1.768 3.615l7.333 2.597-2.823 1.481 4.324 4.461-.445.431-4.324-4.473-1.716 2.86-2.349-7.357zm.049 6.692c-.583.126-1.188.193-1.809.193-4.691 0-8.5-3.809-8.5-8.5s3.809-8.5 8.5-8.5c4.692 0 8.5 3.809 8.5 8.5 0 .619-.066 1.222-.192 1.803l-.959-.337c.094-.474.143-.964.143-1.466 0-4.139-3.361-7.5-7.5-7.5-4.139 0-7.5 3.361-7.5 7.5 0 4.139 3.361 7.5 7.5 7.5.517 0 1.022-.052 1.51-.152l.307.959zm-1.076-3.36c-.239.035-.484.053-.733.053-2.759 0-5-2.24-5-5s2.241-5 5-5c2.76 0 5 2.24 5 5 0 .212-.013.421-.039.626l-.979-.345.01-.281c0-2.208-1.792-4-4-4s-4 1.792-4 4 1.792 4 4 4l.43-.023.311.97zm-.741-5.947c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Web Serverët</h3>
                           <div class="services-descr">
                               Shërbimet tona të menaxhimit të web serverëve përfshijnë: Konsulencë, Konfigurim, Optimizim të shpejtësisë, Monitorim, Përditësime, Auditime të Sigurisë, Balancimin e Ngarkesës, etj. Ju mund të përfitoni nga avantazhi i shërbimeve tona të menaxhimit të web serverëve dhe të përqendroheni në aktivitet kryesore të biznesit tuaj.
                       </div>
                   </div>
               </div>


                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M15 7h4v5h5v6h-5v5h-19v-21h15v5zm-5 11h-9v4h9v-4zm8 0h-7v4h7v-4zm-11-5h-6v4h6v-4zm9 0h-8v4h8v-4zm7 0h-6v4h6v-4zm-12-5v4h7v-4h-7zm-1 0h-9v4h9v-4zm-3-5h-6v4h6v-4zm7 0h-6v4h6v-4z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Serverë për Web Hosting</h3>
                           <div class="services-descr">
                               Themelimi i një kompanie që ofron hosting nuk është e lehtë, kërkon shumë njohuri nga fusha të ndryshme të teknologjisë informative, ne kemi përvojën e duhur për të ju ndihmuar të ndërtoni kompaninë tuaj që ofron këtë shërbim dhe të shërbeni me dinjitetit klientelen tuaj.
                               dergimit dhe pranimit të email-ave, ju mund të krijoni dhe menaxhoni llogaritë e personalizuara të email-ave bazuar në domenin tuaj. Shërbimi ynë përmban module të palëve të treta dhe module që i kemi zhvilluar ne për te ju garantuar një eksperiencë të shpejtë, të sigurtë dhe të shpejtë.
                           </div>
                       </div>
                   </div>
                   
                   <div class="col-sm-6 col-md-6 col-lg-6">
                       <div class="services-item text-center">
                           <div class="services-icon">
                               <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                                   <path d="M14.889 23.652c-.923.227-1.888.348-2.881.348-6.627 0-12.008-5.377-12.008-12s5.381-12 12.008-12c6.628 0 12.009 5.377 12.009 12 0 1.027-.13 2.023-.373 2.975l-.965-.34c.21-.847.321-1.732.321-2.643 0-6.066-4.929-10.992-11-10.992s-11 4.926-11 10.992c0 6.067 4.929 10.993 11 10.993.887 0 1.751-.106 2.577-.304l.312.971zm-2.889-13.652c-1.104 0-2 .896-2 2s.896 2 2 2c.28 0 .546-.057.788-.162l3.06 9.525 2.174-3.622 4.116 4.259 1.879-1.828-4.087-4.216 3.671-1.926-9.694-3.426c.06-.19.093-.393.093-.604 0-1.104-.896-2-2-2zm1.768 3.615l7.333 2.597-2.823 1.481 4.324 4.461-.445.431-4.324-4.473-1.716 2.86-2.349-7.357zm.049 6.692c-.583.126-1.188.193-1.809.193-4.691 0-8.5-3.809-8.5-8.5s3.809-8.5 8.5-8.5c4.692 0 8.5 3.809 8.5 8.5 0 .619-.066 1.222-.192 1.803l-.959-.337c.094-.474.143-.964.143-1.466 0-4.139-3.361-7.5-7.5-7.5-4.139 0-7.5 3.361-7.5 7.5 0 4.139 3.361 7.5 7.5 7.5.517 0 1.022-.052 1.51-.152l.307.959zm-1.076-3.36c-.239.035-.484.053-.733.053-2.759 0-5-2.24-5-5s2.241-5 5-5c2.76 0 5 2.24 5 5 0 .212-.013.421-.039.626l-.979-.345.01-.281c0-2.208-1.792-4-4-4s-4 1.792-4 4 1.792 4 4 4l.43-.023.311.97zm-.741-5.947c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/>
                               </svg>
                           </div>
                           <h3 class="services-title">Shërbime VPN</h3>
                           <div class="services-descr">
                               Duke shfrytëzuar shërbimet tona VPN, ju do të jeni në gjendje të komunikoni në mënyrë të sigurtë me zyrën tuaj të dytë (kudo në botë) sikurse të jeni në të njëjtën ndërtesë. Na lejoni neve që të ndërtojmë një rrjet virtual privat të shpejtë dhe të sigurt për biznesin tuaj!
                       </div>
                   </div>
               </div>



               
           </div>
        </div>
    </section>
   </div>

    <section class="page-section pt-100 mob" style="padding-top:0px">
        <div class="container relative">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <sendMail></sendMail>
                </div>
            </div>
        </div>
    </section>

   <contact></contact>


</template>



<script>
    import hmenu from './header.vue'
    import contact from './contact.vue'
    import sendMail from './sendMail.vue'

    export default {

        mounted:function(){

        },


        components: {
            hmenu,
            sendMail,
            contact
        },


        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }


    }




</script>




<style>


    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>