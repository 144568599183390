<template>
    <hmenu></hmenu>

    <section class="page-section pt-100 mob" id="home">
        <div class="container relative text-center">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s">Sisteme te siguris</h2>
                    <br>
                    <p class="section-title-descr" style="font-size: 22px!important; text-align: left;">
                        <span style="font-weight: 600;">Zgjedhja juaj e sistemit të sigurie për biznesin apo shtëpin tuaj</span>
                        <br>
                        Nëse po planifikoni të rrisni sigurinë e biznesit tuaj. Ne ofrojmë një zgjidhje plotësisht të integruar që ju lejon të monitoroni dhe kontrolloni sigurinë e biznesit tuaj me një ndërfaqe të lehtë për t'u përdorur dhe miqësore me celularin. Me vetëm disa klikime, ju mund të:
                    </p>
                    <div class="col-12 mb-xs-40 camsh">
                        <ul>
                            <li>Monitoroni biznesin tuaj 24/7</li>
                            <li>Armatoseni dhe çarmatosni sistemin tuaj</li>
                            <li>Menaxhoni aksesin e punonjësve</li>
                            <li>Shikoni video me transmetim të drejtpërdrejtë të zonave të siguruara</li>
                            <li>Rregulloni përdorimin e energjisë dhe më shumë</li>
                        </ul>
                    </div>
                    <br>
                    <br>
                    <img src="images/hik1.png"/>
                </div>
            </div>
        </div>
    </section>


    <section class="page-section pt-0" hidden>
        <div class="container relative">
            <div class="row">
                
                <div class="col-sm-6 col-md-4 col-lg-4 mb-md-50">
                    <div class="post-prev-img">
                        <img src="images/hik1.png" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" />
                    </div>
                    <div class="post-prev-title mb-10" hidden>
                        Hikvision
                    </div>
                    <div class="post-prev-text" hidden>
                        Lorem ipsum dolor sit amet, rembe adipiscing elite. Inwege maximus ligula semper metusere pellentesque mattis. Maecenas volutpat, diam enime volutpa. 
                    </div>
                </div>
                
            </div>
            
        </div>
    </section>

    <section class="page-section pt-100 mob" style="padding-top:0px">
        <div class="container relative">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <sendMail></sendMail>
                </div>
            </div>
        </div>
    </section>

    <contact></contact>

</template>

<script>
    import hmenu from './header.vue'
    import contact from './contact.vue'
    import sendMail from './sendMail.vue'

    export default {

        mounted:function(){

        },


        components: {
            hmenu,
            sendMail,
            contact
        },


        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }


    }

</script>



<style>

    .ci-icon {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 12px;
        left: 3px;
        color: #fff;
        text-align: center;
        line-height: 45px;
    }

    .ci-text{
        text-align: left;
    }


    .contact{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        border-radius: 15px;
    }

    .fixed-footer{
        bottom: 0;
        width: 100%;
        position: fixed;        
        padding: 10px 0;
        background-color: #fff;
    }




    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>