<template>
   <div class="page" id="top">
      <main id="main">   
         <transition name="fade" mode="out-in">
            <router-view/>
         </transition>
      </main>
   </div>
</template>


<script>



export default {
    name: 'App',
    components: {
        
    },

    computed: {
      
    },

    mounted:function(){


    }
}

</script>



<style>




.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}


.btn-mod.btn-medium {
    height: auto;
    padding: 11px 15px 12px;
    font-size: 14px;
    width: 100%;
}



    @media
    only screen 
    and (max-width: 988px), (min-device-width: 988px) 
    and (max-device-width: 1024px)  {


        .btn-medium {
            margin-bottom: 10px!important;
        }


   }



    @media only screen and (max-width: 480px){
        .btn-medium {
            margin-bottom: 10px!important;
        }
    }



    .full-wrapper{
   
        max-width: 1318px;
        position: relative;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        padding-right: 30px;
        padding-left: 30px;


    }




    .inner-nav {
        padding-top: 15px;
    }

    .camsh ul li{
        text-align: left;
    }

</style>