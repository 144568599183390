<template>

    <hmenu></hmenu>
    
   <section class="page-section pt-100 mob" id="home">
        <div class="container relative text-center">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s">Sisteme të automatizuara</h2>
                    <p class="section-title-descr" style="font-size: 22px!important;">
                        Sistemet e automatizuara janë krijuar për të kontrolluar në mënyrë profesionale çdo lloj aksesi për: <b>parkimin e makinave, qendrat tregtare, hotelet, spitalet, qendrat e ekspozitës, parqet argëtuese, aeroportet, fitneset, ashensorët</b> si dhe të gjitha llojet e tjera të objekteve publike të vogla ose të mëdha.
                    </p>
                </div>
            </div>
        </div>
    </section>


    <section class="page-section pt-0">
        <div class="container relative">
            
            <div class="row">
                <div class="col-md-8 offset-lg-1 mb-sm-80 order-first order-md-last">

                    <div class="col-12">
                        <div class="blog-item">
                            <h2 class="blog-item-title"><router-link :to="{ name: 'parkingSystem' }">Sistem i parkimit Gparking</router-link></h2>
                            <div class="blog-media">
                                <carousel>
                                    <slide v-for="project in project1" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                            <div class="blog-item-body">
                                <p>
                                    Nëse jeni pronar biznesi ose prone dhe menaxhoni një parking, tashmë e dini rëndësinë e një sistemi të fortë sigurie parkimi.
                                </p>
                            </div>
                            <div class="blog-item-foot">
                                <router-link :to="{ name: 'parkingSystem' }" class="btn btn-mod btn-round  btn-small">Shiko detajet</router-link>
                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="blog-item">
                            <h2 class="blog-item-title"><router-link :to="{ name: 'fitnes' }">Menaxhimi i qasjes së klubeve të fitnesit</router-link></h2>
                            <div class="blog-media">
                                <carousel>
                                    <slide v-for="project in project2" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                            <div class="blog-item-body">
                                <p>
                                    A jeni pronar biznesi i një palestre apo qendre fitnesi? Është e rëndësishme t'u jepni klientave tuaj një mënyrë të përshtatshme për të hyrë në palestrën tuaj. 
                                </p>
                            </div>
                            <div class="blog-item-foot">
                                <router-link :to="{ name: 'fitnes' }" class="btn btn-mod btn-round  btn-small">Shiko detajet</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="blog-item">
                            <h2 class="blog-item-title"><router-link :to="{ name: 'pishina' }">Menaxhimi i qasjes së pishinave</router-link></h2>
                            <div class="blog-media">
                                <carousel>
                                    <slide v-for="project in project3" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                            <div class="blog-item-body">
                                <p>
                                    Ashensorët janë një pjesë thelbësore e infrastrukturës së një ndërtese, duke i lejuar njerëzit të lëvizin shpejt dhe me siguri.
                                </p>
                            </div>
                            <div class="blog-item-foot">
                                <router-link :to="{ name: 'pishina' }" class="btn btn-mod btn-round  btn-small">Shiko detajet</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="blog-item">
                            <h2 class="blog-item-title"><router-link :to="{ name: 'ashensor' }">Menaxhimi i qasjes së ashensorëve</router-link></h2>
                            <div class="blog-media">
                                <carousel>
                                    <slide v-for="project in project4" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                            <div class="blog-item-body">
                                <p>
                                    Gpro ofron sistem inovativ për kontrollin e aksesit që përshtatet në mënyrë të përkryer me kërkesat e pishinave. Menaxhimin e më shumë se një pishine dhe lejimin e aksesit  me të njëjtin anëtarësim.
                                </p>
                            </div>
                            <div class="blog-item-foot">
                                <router-link :to="{ name: 'ashensor' }" class="btn btn-mod btn-round  btn-small">Shiko detajet</router-link>
                            </div>
                        </div>
                    </div>




                </div>

                <projectList></projectList>

            </div>
        </div>
    </section>


    <section class="page-section pt-100 mob" style="padding-top:0px">
        <div class="container relative">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <sendMail></sendMail>
                </div>
            </div>
        </div>
    </section>

    <contact></contact>


</template>

<script>

    import projectList from './projectsList.vue'
    import hmenu from './header.vue'
    import contact from './contact.vue'
    import sendMail from './sendMail.vue'

    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

    export default {
        data () {
            return {
                project1:[
                    'images/parking1.jpg',
                    'images/parking2.png',
                ],

                project2:[
                    'images/fitnes2.png',
                    'images/fitnes1.jpg',
                ],

                project3:[
                    'images/pishina1.jpg',
                    'images/pishina2.jpg',
                ],

                project4:[
                    'images/ashensor1.jpg',
                    'images/ashensor2.jpg',
                ],



            }
        },

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            hmenu,
            contact,
            sendMail,
            projectList
        },


        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

</script>



<style>



    

    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>