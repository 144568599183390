window.$ = window.jQuery = require('jquery');


require('../public/js/all.js');

import "../public/css/bootstrap.min.css"
import "../public/css/style.css"
import "../public/css/style-responsive.css"


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


createApp(App).use(router).mount('#app')