<template>
    <div class="col-md-4 col-lg-3 mt-10">
        <div class="widget">
            <h3 class="widget-title">Projektet</h3>
            <div class="widget-body">
                <ul class="clearlist widget-posts">
                    <li class="clearfix">
                        <router-link :to="{ name: 'parkingSystem' }"><img src="/images/parking1.jpg" alt="" width="100" class="widget-posts-img" /></router-link>
                        <div class="widget-posts-descr">
                            <router-link :to="{ name: 'parkingSystem' }" title="">Sistem i parkimit Gparking</router-link>
                            Postuar me 07 Mars
                        </div>
                    </li>
                    <li class="clearfix">
                        <router-link :to="{ name: 'fitnes' }"><img src="/images/fitnes1.jpg" alt="" width="100" class="widget-posts-img" /></router-link>
                        <div class="widget-posts-descr">
                            <router-link :to="{ name: 'fitnes' }" title="">Menaxhimi i klubeve të fitnesit</router-link>
                            Postuar me 15 Prill
                        </div>
                    </li>
                    <li class="clearfix">
                        <router-link :to="{ name: 'pishina' }"><img src="/images/pishina1.jpg" alt="" width="100" class="widget-posts-img" /></router-link>
                        <div class="widget-posts-descr">
                            <router-link :to="{ name: 'pishina' }" title="">Menaxhimi i pishinave</router-link>
                            Postuar me 08 Shtator
                        </div>
                    </li>
                    <li class="clearfix">
                        <router-link :to="{ name: 'ashensor' }"><img src="/images/ashensor1.jpg" alt="" width="100" class="widget-posts-img" /></router-link>
                        <div class="widget-posts-descr">
                            <router-link :to="{ name: 'ashensor' }" title="">Menaxhimi i qasjes së ashensorëve</router-link>
                            Postuar me 22 Tetor
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>