import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router'



import Home from '../components/home.vue'
import itServices from '../components/itServices.vue'
import camServices from '../components/camServices.vue'
import webServices from '../components/webServices.vue'
import iotServices from '../components/iotServices.vue'
import parkingSystem from '../components/iotinfo/parking.vue'
import ashensor from '../components/iotinfo/ashensor.vue'
import fitnes from '../components/iotinfo/fitnes.vue'
import pishina from '../components/iotinfo/pishina.vue'



const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },    
    {
        path: '/itServices',
        name: 'itServices',
        component: itServices
    },    
    {
        path: '/camServices',
        name: 'camServices',
        component: camServices
    },    
    {
        path: '/webServices',
        name: 'webServices',
        component: webServices
    },    
    {
        path: '/iotServices',
        name: 'iotServices',
        component: iotServices
    },    
    {
        path: '/iotInfo/parkingSystem',
        name: 'parkingSystem',
        component: parkingSystem
    },    
    {
        path: '/iotInfo/ashensor',
        name: 'ashensor',
        component: ashensor
    },    
    {
        path: '/iotInfo/fitnes',
        name: 'fitnes',
        component: fitnes
    },    
    {
        path: '/iotInfo/pishina',
        name: 'pishina',
        component: pishina
    },
   
]



const router = createRouter({ history: createWebHistory(), routes })
export default router