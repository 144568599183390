<template>

    <hmenu></hmenu>

    <section class="page-section pt-100 mob">
        <div class="container relative">
            <div class="row">
                <div class="col-md-8 mb-sm-80">
                    
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s" style="padding-bottom: 25px;">Menaxhimi i qasjes së klubeve të fitnesit</h2>
                    <div class="blog-item mb-80 mb-xs-40">
                        <div class="blog-item-body">
                            <div class="lead mt-0">
                                <p>
                                    A jeni pronar biznesi i një palestre apo qendre fitnesi? Është e rëndësishme t'u jepni klientave tuaj një mënyrë të përshtatshme për të hyrë në palestrën tuaj. 
                                </p>
                            </div>
                            
                            <div class="blog-media mt-40 mb-40 mb-xs-30">

                                <carousel>
                                    <slide v-for="project in project1" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>

                            </div>
                            
                            <p>

                                Kur kërkoni sisteme sigurie, përfitimet e sistemeve të kontrollit të aksesit për palestrat dhe qendrat e fitnesit janë të vështira për t'u injoruar! Merrni parasysh këto avantazhe që sistemet e kontrollit të aksesit që Gpro ofronë për biznesit tuaj.
                                <br>

                                <h4>Përfitimet kryesore të kontrollit të aksesit për palestrat që Gpro offron:</h4>
                                <ul>
                                    <li>Hyrja pa prekje - Këto ditë, është e rëndësishme që palestrat të sigurojnë shëndetin e anëtarëve dhe vizitorëve kur hyjnë dhe dalin. Qendrat e fitnesit po zgjedhin të shmangin pikat e hyrjes që kërkojnë që anëtarët e tyre të prekin sipërfaqet dhe të ekspozohen ndaj mikrobeve. Sistemet e kontrollit të aksesit lejojnë anëtarët të hyjnë dhe të dalin lehtësisht nga ndërtesa pa pasur nevojë të prekin asnjë sipërfaqe.</li>
                                    <li>Anëtarësimet në palestër - Një nga përfitimet kryesore të përdorimit të sistemeve të kontrollit të aksesit në fitnesit tuaj është se hyrjen e bëni shum të lehtë. Sistemet e kontrollit të aksesit integrohen me kartat ose çipat e anëtarësimit të anëtarëve tuaj. Ata thjesht mund të përdorin kartën e tyre për të hyrë në objekt kur të mbërrijnë. Gjithashtu, nëse keni kufizime të anëtarësimit, sistemi i kontrollit të aksesit do të mundësojë ose çaktivizojë hyrjen në përputhje me rrethanat.</li>
                                    <li>Qasje në çdo kohë - Disa palestra u lejojnë anëtarëve akses 24/7, por kanë vetëm një staf të brendshëm gjatë orëve të caktuara. Sistemi i kontrollit të aksesit do të veprojë si një roje automatike e sigurisë që lejon ose ndalon aksesin pas orarit të punës, gjatë pushimeve dhe më shumë.</li>
                                    <li>Me kosto efektive - Punësimi i një punëtori mund të jetë shtrenjtë për biznesin tuaj. Sistemi i kontrollit të aksesit mund t'ju ndihmojnë të kurseni para në shpenzimet tuaja të sigurisë duke mundësuar një sistem sigurie.</li>
                                    <li>Përputhshmëria me shumë pika - Shumë palestra dhe qendra fitnesi pika të shumta. Ata kanë nevojë për një sistem sigurie me shumë vende që mund ta menaxhojnë nga një vend i vetëm. Sistemet e kontrollit të aksesit të shum një apo më shum pikave Gpro bën një zgjidhje cloud. Kjo e bën shumë më të lehtë për biznesin të mbikëqyrë sigurinë në çdo vend.</li>
                                </ul>
                            </p>
                            


                            <div class="row">
                                <div class="col-12 wow fadeInUpShort">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Diferenca vjetore e te hyrave</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/ashensro_g1.png"/>
                                </div>
                            </div>
                            <br>

                            
                            <blockquote>
                                <p>
                                    Integrimi i programit për menaxhimin e orarit të punes
                                    Gpro offron modul shtese për menaxhimin e orarit të punes. Kjo ju lehtëson manaxhimin e orarit të punes të stafit tuaj të hyrjes dhe dajljes. Pjesa më e mirë është se kjo mund të integrohet lehtësisht me sistemet e aksesit, në mënyrë që të mund të merrni gjithmonë të dhëna në kohë reale për ndërrimet e punonjësve tuaj.
                                </p>
                            </blockquote>



                            <div class="row">
                                <div class="col-12 wow fadeInUpShort" data-wow-duration="1.2s" data-wow-offset="205">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Statistikat e klientave</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/ashensro_g5.png" />
                                </div>
                            </div>
                            <br>
                            
                        </div>
                    </div>
                    <sendMail></sendMail>
                </div>

                <projectList></projectList>


            </div>
            
        </div>
    </section>

    <contact></contact>

</template>


<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import projectList from '../projectsList.vue'
    import hmenu from '../header.vue'
    import contact from '../contact.vue'
    import sendMail from '../sendMail.vue'

    export default {
        data () {
            return {
                project1:[
                    '/images/fitnes2.png',
                    '/images/fitnes1.jpg',
                ],
            }
        },

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            projectList,
            hmenu,
            contact,
            sendMail,
        },


        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

    }
</script>







<style>

    
    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>