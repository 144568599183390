<template>
    <div class="mb-80 mb-xs-40" v-if="!sukses">
    <h4 class="blog-page-title">Për më informata na kontaktoni! </h4>
    <form @submit.prevent="sendMail" id="form" class="form">
        <p v-if="error" style="text-align: center; color:red"><b>Ju lutem plotesoni të gjitha fushat</b></p>
        <p v-if="error1" style="text-align: center; color:red"><b>Gabim në dërgimin e mesazhit ju lutem provoni përsëri</b></p>
        <div class="row mb-30 mb-md-20">
            <div class="col-md-6 mb-md-20">
                <label for="name">Emri *</label>
                <input v-model="form.emri" type="text" name="emri" id="emri" class="input-md round form-control" placeholder="Shkruani emrin tuaj" :class="(emri_error)?'input_error':''">
            </div>
            <div class="col-md-6">
                <label for="email">Mbiemri *</label>
                <input v-model="form.mbiemri" type="text" name="mbiemri" id="mbiemri" class="input-md round form-control" placeholder="Shkruani mbiemrin tuaj" :class="(mbiemri_error)?'input_error':''">
            </div>
        </div>
        <div class="row mb-30 mb-md-20">
            <div class="col-md-6 mb-md-20">
                <label for="name">Emaili *</label>
                <input v-model="form.email" type="email" name="email" id="email" class="input-md round form-control" placeholder="Shkruani emailin tuaj" :class="(email_error)?'input_error':''">
            </div>
            <div class="col-md-6">
                <label for="email">Telefoni *</label>
                <input v-model="form.tel" type="number" name="tel" id="tel" class="input-md round form-control" placeholder="Shruani telefonin tuaj" :class="(tel_error)?'input_error':''">
            </div>
        </div>
        <div class="mb-30 mb-md-20">
            <label for="comment">Mesazhi *</label>
            <textarea v-model="form.msg" name="msg" id="msg" class="input-md round form-control" rows="6" placeholder="Shkruani mesazhin tuaj" maxlength="400" :class="(msg_error)?'input_error':''"></textarea>
        </div>
        <br>
        <button v-if="!busy" type="submit" class="btn btn-mod btn-medium btn-round">
            Dërgo mesazhin
        </button>
        <button v-else type="button" disabled class="btn btn-mod btn-medium btn-round">
            <i class="fa fa-spinner fa-spin"></i> Ju lutem prisni ...
        </button>
    </form>
</div>
<div class="mb-80 mb-xs-40" v-else>
    <div class="container">
        <div class="row contact">
            <div class="col-12">
                <h4 style="color: green;" >Mesazhi ësht dërguar me sukses</h4>
                <h5 style="color: green;" >Dikush nga stafi do të ju kontaktoj shum shpejt. Ju faliminderit!</h5>
            </div>
        </div>
    </div>
    <br>
    <br>
</div>
</template>




<script>

    import axios from 'axios';

    export default {
        data () {
            return {
 
                sukses:false,
                busy:false,
                error:false,
                emri_error:false,
                mbiemri_error:false,
                email_error:false,
                tel_error:false,
                msg_error:false,
                error1:false,

                form:{
                    emri:'',
                    mbiemri:'',
                    email:'',
                    tel:'',
                    msg:'',
                }

            }
        },



        methods : {
            async sendMail(){
                this.busy = true 
                this.error = false

                if(this.form.emri.length < 3){
                    this.emri_error = true
                    this.error = true
                    this.busy = false 
                }else{
                    this.emri_error = false
                }

                if(this.form.mbiemri.length < 3){
                    this.mbiemri_error = true
                    this.error = true
                    this.busy = false 
                }else{
                    this.mbiemri_error = false
                }

                if(this.form.email.length < 3){
                    this.email_error = true
                    this.error = true
                    this.busy = false 
                }else{
                    this.email_error = false
                }

                if(this.form.tel.length < 3){
                    this.tel_error = true
                    this.error = true
                    this.busy = false 
                }else{
                    this.tel_error = false
                }

                if(this.form.msg.length < 3){
                    this.msg_error = true
                    this.error = true
                    this.busy = false 
                }else{
                    this.msg_error = false
                }


                if(this.error == false){
                    axios.post(`/api/sendMail.php`, this.form).then(res=>{
                        console.log(res);
                        if(res.data == 'sukses'){
                            this.sukses = true
                            this.error1 = false
                        }else{
                            this.sukses = false
                            this.error1 = true
                        }
                        this.busy = false
                    }).catch(error=>{
                        console.log(error)
                        this.busy = false
                        this.sukses = false
                    })

                }

            }
        },

        components: {
           
        },

    

    }
</script>
