<template>

    <hmenu></hmenu>


    <section class="home-section bg-light-alfa-50 parallax-5" data-background="/images/full-width-images/section-bg-22.jpg" id="home" style="background-image: url('/images/full-width-images/section-bg-22.jpg'); background-position: 50% 0px;">
        <div class="container min-height-100vh d-flex align-items-center pt-100 pb-100">
            <div class="home-content text-start">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <h1 class="hs-line-3 mb-30 mb-xs-20 wow fadeInUpShort slogan_mob" data-wow-delay=".1s">Le të bëhemi dixhital!</h1>
                        <h2 class="hs-line-5 mb-60 mb-xs-40 wow fadeInUpShort" data-wow-delay=".2s">
                            <span data-period="2800" data-type='[ "Web developing.", "Sistemte te automa", "Kamera sigurie."]' class="typewrite" aria-hidden="true"><span class="wrap"></span></span>
                        </h2>
                        <div class="local-scroll wow fadeInUpShort" data-wow-delay=".3s">
                            <div class="row">
                                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                    <router-link :to="{ name: 'itServices' }" class="btn btn-mod btn-medium btn-round me-md-1">Shërbime të IT's</router-link >
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                    <router-link :to="{ name: 'camServices' }" class="btn btn-mod btn-medium btn-round me-md-1">Kamera & Alarme</router-link>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                    <router-link :to="{ name: 'webServices' }" class="btn btn-mod btn-medium btn-round me-md-1">Web aplikacione</router-link>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                    <router-link :to="{ name: 'iotServices' }" class="btn btn-mod btn-medium btn-round me-md-1">Sisteme të automatizuara</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <div class="contact fixed_footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-lg-4 pb-20">
                        <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".1s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeScaleIn;">
                            <div class="ci-icon">
                                <i class="fa fa-phone-alt" aria-hidden="true"></i>
                            </div>
                            <div class="ci-text">
                                <b>+383 45 694 444</b><br>
                                <b>+383 49 200 203</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4 pb-20">
                        <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".1s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeScaleIn;">
                            <div class="ci-icon">
                                <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
                            </div>
                            <div class="ci-text">
                                <b>Rr. Zhuj Selmani Nr8</b><br>
                                <b>Pejë, Kosovë</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-4 pb-20">
                        <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".2s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeScaleIn;">
                            <div class="ci-icon">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="ci-text">
                                <b>https://gpro-ks.com</b><br>
                                <b>info@gpro-ks.com</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




    <nav class="mobile-bottom-nav" hidden>
        <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
            <div class="mobile-bottom-nav__item-content">
                <i class="material-icons">home</i>
                SHËRBIME<br>TË IT'S
            </div>      
        </div>
        <div class="mobile-bottom-nav__item">       
            <div class="mobile-bottom-nav__item-content">
                <i class="material-icons">mail</i>
                KAKEMRA<br>& ALARME
            </div>
        </div>
        <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content">
                <i class="material-icons">person</i>
                WEB<br>APLIKACIONE
            </div>      
        </div>
        
        <div class="mobile-bottom-nav__item">
            <div class="mobile-bottom-nav__item-content">
                <i class="material-icons">phone</i>
                SISTEME TË<br>AUTOMATIZUARA
            </div>      
        </div>
    </nav> 


</template>

<script>
    import hmenu from './header.vue'

    export default {

        mounted:function(){

        },


        components: {
            hmenu,
        }


    }

</script>


<style>



    .fixed_footer{
        bottom: 0;
        width: 100%;
        position: fixed;        
        padding: 10px 0;
    }


    .contact{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        border-radius: 15px;
    }



    .btn-mod.btn-medium {
        border-radius: 15px;
    }


    .slogan_mob{
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        padding-bottom: 22px;
    }

    @media only screen and (max-width: 768px){
        .ci-icon {
            width: 25px!important;
            height: 25px!important;
            position: absolute;
            top: 15px;
            left: 3px;
            color: #fff;
            text-align: center;
            line-height: 25px!important;
            top: 17px;
        }



        .slogan_mob{
            text-align: center;
        }


    }








.cd__main{
   position: relative;
   width: 360px !important;
   height: 720px;
   border: 2px solid #bbb;
}
/* end demo only */
.mobile-bottom-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 80px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #252525;
}
.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobile-bottom-nav__item--active {
  color: red;
}
.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;

}


.mobile-bottom-nav__item-content i{
    color: #fff;
    font-size: 30px;

}
</style>