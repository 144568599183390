<template>

    <hmenu></hmenu>


    <section class="page-section pt-100 mob">
        <div class="container relative">
            <div class="row">
                <div class="col-md-8 mb-sm-80">
                    
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s" style="padding-bottom: 25px;">Sistem i parkimit Gparking</h2>
                    <div class="blog-item mb-80 mb-xs-40">
                        <div class="blog-item-body">
                            <div class="lead mt-0">
                                <p>
                                    Nëse jeni pronar biznesi ose prone dhe menaxhoni një parking, tashmë e dini rëndësinë e një sistemi të fortë sigurie parkimi.
                                </p>
                            </div>
                            
                            <div class="blog-media mt-40 mb-40 mb-xs-30">

                                <carousel>
                                    <slide v-for="project in project1" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>

                            </div>
                            
                            <p>
                                Rojet e sigurisë dhe kamerat janë të zakonshme dhe sigurisht të rëndësishme, por ka një fushë tjetër që nuk mund të anashkaloni—kontrolli i hyrjes në parking. 
                                <br>
                                <br>
                                Gpro ofron sistemin e kontrollit të aksesit për parkim që është krijuar për të hyrej dhe daljen në pronat tuaja të parkimit.
                                <br>
                                <br>
                                Bizneset duhet të ndërmarrin hapat e nevojshëm për të siguruar parkingjet e tyre, duke përfshirë instalimin e një sistemi të kontrollit të aksesit të parkimit. 
                                <br>
                                <br>
                                Marrja e këtyre masave paraprake do të sigurojë që vetëm individët e autorizuar të mund të kenë çasje në parking dhe të sigurohen raporte të hyrjes dhe daljes.
                            </p>
                            

                            <div class="row">
                                <div class="col-12 wow fadeInUpShort">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Të hyrat ditore</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/parking_g1.png"/>
                                </div>
                            </div>
                            <br>


                            <blockquote>
                                <p>
                                    Gpro ju ofron metoda të ndryshme të sigurisë së parkimit, softueri i dizajnuar nga Gpro siguron manaxhimin e klintve ditore, mujore të perhershem etj. Raporte të të ghitha llojeve dhe çasje nga telefoni.
                                </p>
                            </blockquote>
                            

                            <div class="row">
                                <div class="col-12 wow fadeInUpShort">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Statistikat e hyrjeve & daljeve</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/parking_g2.png"/>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-md-6 col-lg-6 col-12">
                                    <div class="work-process-item text-center0">
                                        <h3 class="work-process-title">Statusi i parkingjeve</h3>
                                        <img src="/images/parking_g3.png"/>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-12">
                                    <div class="work-process-item text-center0">
                                        <h3 class="work-process-title">Te hyrat ditore</h3>
                                        <img src="/images/parking_g4.png"/>
                                    </div>
                                </div>
                            </div>

                            <br>
                            <br>

                            <div class="row">
                                <div class="col-lg-6 wow fadeInUpShort" data-wow-duration="1.2s" data-wow-offset="205">
                                    <div class="row">
                                        <div class="col-12">
                                            <h2 class="section-title mb-60 mb-sm-30">Njoftime automatike</h2>
                                            <p>Njofto klientat ne menyr automarike mbi abonimin e tyre!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">                                                               
                                    <div class="call-action-3-images mt-xs-0 text-end">
                                        <img src="/images/parking_g6.png" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="205" />
                                    </div>                                                                              
                                </div>
                            </div>
                        </div>
                    </div>
                    <sendMail></sendMail>
                </div>
                <projectList></projectList>
            </div>
            
        </div>
    </section>

    <contact></contact>

</template>


<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import projectList from '../projectsList.vue'
    import hmenu from '../header.vue'
    import contact from '../contact.vue'
    import sendMail from '../sendMail.vue'


    import axios from 'axios';

    export default {
        data () {
            return {
                project1:[
                    '/images/parking1.jpg',
                    '/images/parking2.png',
                ],

    

            }
        },



        methods : {

        },

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            projectList,
            hmenu,
            contact,
            sendMail,
        },

        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

    }
</script>







<style>

    
    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }


    .input_error{
        border-color: #fd7171!important;
    }



    .input_error::placeholder {
        color: #fd7171!important;
        opacity: 1; /* Firefox */
    }



</style>