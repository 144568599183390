<template>

    <hmenu></hmenu>


    <section class="page-section pt-100 mob">
        <div class="container relative">
            <div class="row">
                <div class="col-md-8 mb-sm-80">
                    
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s" style="padding-bottom: 25px;">Kontrolli i çasjes së ashënsorëve</h2>
                    <div class="blog-item mb-80 mb-xs-40">
                        <div class="blog-item-body">
                            <div class="lead mt-0">
                                <p>
                                    Ashensorët janë një pjesë thelbësore e infrastrukturës së një ndërtese, duke i lejuar njerëzit të lëvizin shpejt dhe me siguri.
                                </p>
                            </div>
                            
                            <div class="blog-media mt-40 mb-40 mb-xs-30">

                                <carousel>
                                    <slide v-for="project in project1" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>

                            </div>
                            
                            <p>
                                Gpro ju offron sistem të automatizuar të çasjes në ashensore, pasi një përdorues të jetë vërtetuar, sistemi i sigurisë së ashensorit punon me sistemin e kontrollit të aksesit për të monitoruar përdorimin e ashensorit/ashensorëve.
                            </p>
                            
                            <p>
                                Me programin e koduar dhe dizajnuar nga Gpro ju keni çasje në raporte në çfar do kohe se kush po e përdor ashensorin, kur e përdor dhe ku po shkojnë.
                            </p>



                            <div class="row">
                                <div class="col-12 wow fadeInUpShort">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Diferenca vjetore e te hyrave</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/ashensro_g1.png"/>
                                </div>
                            </div>
                            <br>

                            
                            <blockquote>
                                <p>
                                    Sistemi mund të përdoret për të zbuluar çështjet e mundshme të sigurisë dhe për të ndihmuar në parandalimin e aksesit të paautorizuar ose keqpërdorimit të ashensorëve. 
                                </p>
                            </blockquote>



                            <div class="row">
                                <div class="col-12 wow fadeInUpShort" data-wow-duration="1.2s" data-wow-offset="205">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h3 class="">Statistikat e banorëve</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-12">                                                               
                                    <img src="/images/ashensro_g5.png" />
                                </div>
                            </div>
                            <br>
                            
                        </div>
                    </div>
                    <sendMail></sendMail>
                </div>
                <projectList></projectList>
            </div>
        </div>
    </section>



    <contact></contact>


</template>


<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import projectList from '../projectsList.vue'
    import contact from '../contact.vue'
    import hmenu from '../header.vue'
    import sendMail from '../sendMail.vue'

    export default {
        data () {
            return {
                project1:[
                    '/images/ashensor1.jpg',
                    '/images/ashensor2.jpg',
                ],
            }
        },

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            projectList,
            hmenu,
            contact,
            sendMail,
        },

        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

    }
</script>



<style>

    
    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>