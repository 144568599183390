<template>
    <div class="fixed-footer">
        <div class="container">
            <div class="row contact">
                <div class="col-sm-4 col-6 col-lg-4 pb-20 mobile_col">
                    <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".1s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeScaleIn;">
                        <div class="ci-icon">
                            <i class="fa fa-phone-alt" aria-hidden="true"></i>
                        </div>
                        <div class="ci-text">
                            <b>+383 45 694 444</b><br>
                            <b>+383 49 200 203</b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-6 col-lg-4 pb-20 mhide">
                    <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".1s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeScaleIn;">
                        <div class="ci-icon">
                            <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
                        </div>
                        <div class="ci-text">
                            <b>Rr. Zhuj Selmani Nr8</b><br>
                            <b>Pejë, Kosovë</b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-6 col-lg-4 pb-20 mobile_col">
                    <div class="contact-item wow fadeScaleIn animated" data-wow-delay=".2s" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeScaleIn;">
                        <div class="ci-icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div class="ci-text">
                            <b>https://gpro-ks.com</b><br>
                            <b>info@gpro-ks.com</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>


    .ci-icon {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 12px;
        left: 3px;
        color: #fff;
        text-align: center;
        line-height: 45px;
    }

    .ci-text{
        text-align: left;
    }


    .contact{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        border-radius: 15px;
    }

    .fixed-footer{
        bottom: 0;
        width: 100%;
        position: fixed;        
        padding: 10px 0;
        background-color: #fff;
    }




    .mhide{
        display: block;
    }

    @media only screen and (max-width: 768px){
        .ci-icon {
            width: 25px!important;
            height: 25px!important;
            position: absolute;
            top: 15px;
            left: 3px;
            color: #fff;
            text-align: center;
            line-height: 25px!important;
            top: 17px;
        }

        .contact-item {
            padding: 6px 0 0 35px!important;
        }

        .mhide{
            display: none;
        }


        .ci-text{
            font-size: 14px;
        }


        .mobile_col{
            padding-left: 8px!important;
            padding-right: 8px!important;
        }

    }



</style>