<template>    
    <hmenu></hmenu>

    <section class="page-section pt-100 mob">
        <div class="container relative">
            <div class="row">
                <div class="col-md-8 mb-sm-80">
                    
                    <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s" style="padding-bottom: 25px;">Menaxhimi i qasjes së pishinave</h2>
                    <div class="blog-item mb-80 mb-xs-40">
                        <div class="blog-item-body">
                            <div class="lead mt-0">
                                <p>
                                    Gpro ofron sistem inovativ për kontrollin e aksesit që përshtatet në mënyrë të përkryer me kërkesat e pishinave. Menaxhimin e më shumë se një pishine dhe lejimin e aksesit  me të njëjtin anëtarësim.
                                </p>
                            </div>
                            
                            <div class="blog-media mt-40 mb-40 mb-xs-30">

                                <carousel>
                                    <slide v-for="project in project1" :key="slide">
                                        <img :src="project" alt="" />
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>

                            </div>
                            
                            <p>
                                Gpro bën një zgjidhje ideale per paketa te ndryshme edhe për kalime të rastësishme, biletat për akses të rastësishëm mund të jenë ose një barkod i printuar ose një QR kod. 
                                <br>
                                Klientët tuaj mund të hyjnë në pishinë shpejt dhe automatikisht përmes mjeteve të ndryshme të identifikimit si QR kod, barkod, kartat, shiritat e dorës, çipat ose telefoni i tyre, mënyra shumë të përshtatshme për pishina.

                            </p>
                            
                            
                        </div>
                    </div>
                    <sendMail></sendMail>
                </div>

                <projectList></projectList>


            </div>
            
        </div>
    </section>

    <contact></contact>

</template>


<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import projectList from '../projectsList.vue'
    import hmenu from '../header.vue'
    import contact from '../contact.vue'
    import sendMail from '../sendMail.vue'

    export default {
        data () {
            return {
                project1:[
                    '/images/pishina1.jpg',
                    '/images/pishina2.jpg',
                ],
            }
        },

        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            projectList,
            hmenu,
            contact,
            sendMail,
        },

        mounted:function(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }


    }
</script>






<style>

    
    @media only screen and (max-width: 768px){
        .mob {
            margin-top: 30px;
        }
    }

</style>